<template>
  <section>
    <h3 id="hSocialMedia">{{ $t("headers.social-media") }}</h3>
    <IconLinks :iconLinks="socialMediaSites" />
  </section>
</template>

<script>
import IconLinks from '@/components/common/IconLinks.vue';

export default {
  name: 'SocialMedia',
  components: {
    IconLinks,
  },
  data() {
    return {
      socialMediaSites: [
        {
          title: 'Facebook',
          url: 'https://www.facebook.com/Begravdtheband',
          imageLocation: '/images/icons/social-media/facebook.png',
        },
        {
          title: 'Instagram',
          url: 'https://www.instagram.com/begravd/',
          imageLocation: '/images/icons/social-media/instagram.png',
        },
        {
          title: 'Youtube',
          url: 'https://www.youtube.com/channel/UC9q1yxtffaNEoMNgVR8QT1A',
          imageLocation: '/images/icons/social-media/youtube.png',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
