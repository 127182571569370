<template>
  <section>
    <h2 id="hContact">{{ $t("headers.contact") }}</h2>
    <p id="pContactInfo" class="readFriendlyLayout">
      {{ $t("view.contact.text") }}
    </p>
    <social-media />
    <contact-form />
  </section>
</template>

<script>
import ContactForm from '@/components/contact/ContactForm.vue';
import SocialMedia from '@/components/contact/SocialMedia.vue';

export default {
  name: 'Contact',
  components: {
    ContactForm,
    SocialMedia,
  },
};
</script>

<style>

</style>
